* {
   box-sizing: border-box;
}

body {
   font-size: 0.875rem;
   background: #eff3f9;
   margin: 0 !important;
   font-family: 'Roboto' !important;
   background: #fafafa !important;
}

a {
   text-decoration: none;
   transition: 0.2s;
   outline: none;
}

a:hover {
   transition: 0.2s;
}
